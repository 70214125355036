import React from "react";
import colors from "../lib/colors";
import styled from "@emotion/styled";
import Ui from "../components/Ui";
import DefaultLayout from "../components/DefaultLayout";
import Avatar from "../components/Avatar";
import Hero from "../components/bricks/Hero";
import Strip from "../components/ui/Strip";
import {Text} from "../components/ui/Text";
import xcolors from "../lib/xcolors";
import ThemeBox from "../components/bricks/ThemeBox";
import {themeBright} from "../page-styles/landingpage.css";
import ThemedSingleFeature from "../components/bricks/ThemedSingleFeature";
import {ThemedButton} from "../components/xui/ThemedButton";

const aboutBreak = "@media(max-width: 750px)";

const SuspectList = styled("div")({
  display: "flex",
  maxWidth: "70rem",
  width: "100%",
  margin: "0 auto 2rem",
  flexWrap: "wrap",
  [aboutBreak]: {
    flexDirection: "column",
    alignItems: "center",
  },
});

const OuterSuspect = styled("div")({
  width: "33.333%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [aboutBreak]: {width: "100%"},
});

const SubspectContainer = styled("div")({
  flex: "auto",
  margin: "2rem 0.5rem 2rem",
  border: `0.05rem solid ${colors.borderGray}`,
  padding: "3rem 2rem 1rem",
  position: "relative",
  [aboutBreak]: {
    flex: "none",
    margin: "2rem 0 3rem",
    maxWidth: "30rem",
    padding: "3rem 1rem 1rem",
    backgroundColor: colors.white,
  },
});

const SubspectImage = styled(Avatar)({
  marginLeft: "-2rem",
  marginTop: "-2rem",
  boxShadow: `0 0 0 3px ${colors.active}`,
});

const ImageBox = styled("div")({
  position: "absolute",
  top: 0,
  left: "50%",
});

const SubspectContent = styled("div")({
  fontSize: "0.8rem",
  lineHeight: 1.5,
  color: xcolors.gray700,
  "& b": {color: colors.active},
});

const Suspect = ({children, imgFileName}) => (
  <OuterSuspect>
    <SubspectContainer>
      <ImageBox>
        <SubspectImage size="lg" name={imgFileName} />
      </ImageBox>
      <SubspectContent>{children}</SubspectContent>
    </SubspectContainer>
  </OuterSuspect>
);

const Team = ({title, children, bottom}) => (
  <Strip sp={6} bg="gray100" py={7}>
    <Text as="h2" preset="bold" align="center" color="gray800" size={5}>
      {title}
    </Text>
    <SuspectList>{children}</SuspectList>
    {bottom && (
      <Text align="center" color="gray600">
        {bottom}
      </Text>
    )}
  </Strip>
);

const About = ({location}) => (
  <DefaultLayout
    title="About"
    description="Why we believe in Beautiful Project Management For Game Development."
    location={location}
  >
    <Hero
      size="sm"
      title="Who are we?"
      subline="Get to know our team and mission."
      bottomImg="magician.svg"
    />
    <Team
      title="The suspects"
      bottom={
        <>
          PS: we can be reached at{" "}
          <Ui.Link href="mailto:hello@codecks.io">hello@codecks.io</Ui.Link>
        </>
      }
    >
      <Suspect imgFileName="riad">
        <b>Riad</b> is the co-founder of Maschinen-Mensch and believes that Street Fighter 2 is the
        most beautiful video game ever created. He also believes that Codecks is the best project
        management tool for game developers. He has been creating video games for over 10 years and
        considers himself a productivity nerd: scrum, kanban, extreme programming, waterfall. He
        tried it all.
      </Suspect>
      <Suspect imgFileName="daniel">
        Saying that <b>Daniel</b> loves programming would be an understatement. He has a
        quasi-mystical relationship with it. Maybe it is more accurate to say that programming loves
        him? When he's not wrangling code for Codecks or participating in hackathons, he humbles his
        opponents on Age of Empires II (1v6! You monster).
      </Suspect>
      <Suspect imgFileName="lorenzo">
        <b>Lorenzo</b> is known for not smiling often, particularly in pictures (he says he looks
        stupid when he does). But there’s one thing that brings him lots of joy: helping people
        solve their problems. That’s why he loves introducing indie game developers to Codecks, and
        support those who are already using it.
      </Suspect>
      <Suspect imgFileName="jo">
        Of the many things <b>Johannes</b> (he also is a Maschinen-Mensch co-founder) dislikes,
        using project management software holds a long term high score. After all he’s a talkative
        creative and would do anything to avoid boring tools instead of direct communication. He’s
        still flabbergasted how effective and fun using Codecks is, even for a person like him.
      </Suspect>
      <Suspect imgFileName="micha">
        At some point, Codecks needed a virtouse designer. Well, at least they found <b>Michael</b>.
        He got super infected with the project and eventually became a team member. When he’s not
        pixel-pushing new interface components for Codecks, he’s probably lost in never ending games
        of Europa Universalis or Imperator: Rome.
      </Suspect>
      <Suspect imgFileName="emmanuel">
        <b>Emmanuel</b> is an old man, but don't remind him of that. He spent too many years as a
        project manager, for video games, web applications, and even hard-core industrial projects.
        The scars run deep. Most of his evenings and week-ends are devoted to video games, board
        games, and miniature painting. That's what happens when you get old.
      </Suspect>
    </Team>
    <ThemeBox theme={themeBright}>
      <ThemedSingleFeature
        title="Check our Community Hub"
        img="community/community-showcase.jpg"
        description={<>This page features community-made resources like integrations and videos.</>}
      >
        <ThemedButton to="/community/">Open Community Hub</ThemedButton>
      </ThemedSingleFeature>
    </ThemeBox>
  </DefaultLayout>
);

export default About;
